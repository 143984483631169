<template>
  <div class="cv-bloc">
    <div class="bg-white py-6 sm:py-8 lg:py-12">
  <div class="max-w-screen-xl px-4 md:px-8 mx-auto">

    <div class="grid grid-cols-2 md:grid-cols-4 md:divide-x gap-8 md:gap-0">
      <!-- stat - start -->
      <div class="flex flex-col items-center md:p-4">
        <div class="text-indigo-500 text-xl sm:text-2xl md:text-3xl font-bold">{{age}}</div>
        <div class="text-sm sm:text-base font-semibold">Ans</div>
      </div>
      <!-- stat - end -->

      <!-- stat - start -->
      <div class="flex flex-col items-center md:p-4">
        <div class="text-indigo-500 text-xl sm:text-2xl md:text-3xl font-bold">
           <font-awesome-icon :icon="['far', 'envelope']" />
<!-- <div class="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center flex-shrink-0 bg-indigo-500 text-white rounded-lg md:rounded-xl shadow-lg">
  <span class="material-icons-outlined material-icons w-6 h-6">
mail
</span>
 
  </div> -->


        </div>
        <div class="text-sm sm:text-base font-semibold"><a :href="'mailto:' + email" >{{email}}</a></div>
      </div>

      <!-- stat - start -->
      <div class="flex flex-col items-center md:p-4">
        <div class="text-indigo-500 text-xl sm:text-2xl md:text-3xl font-bold">+{{experience}}</div>
        <div class="text-sm sm:text-base font-semibold">Années d'expériences</div>
      </div>
      <!-- stat - end -->

      <!-- stat - start -->
      <div class="flex flex-col items-center md:p-4">
        <div class="text-indigo-500 text-xl sm:text-2xl md:text-3xl font-bold">
            <font-awesome-icon icon="map-marker-alt" />
          
        </div>
        <div class="text-sm sm:text-base font-semibold">Le Mans</div>
      </div>
      <!-- stat - end -->
    </div>
  </div>
</div>
  </div>
</template>

<script>
export default {
 name: 'cvBloc',
 computed: {
   age(){
     return this.$moment().diff('1990-07-27', 'years');
   }
   ,experience(){
     return this.$moment().diff('2011-07-17', 'years');
   }
 },
 data: () => ({
    email:"anthony.lahire@gmail.com"
 })
}
</script>

<style>

</style>