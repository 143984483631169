<template>
<!-- Navbar goes here -->
	<nav class="bg-white  sticky top-0 z-30">
		<div class="max-w-screen-2xl px-4 md:px-8 mx-auto">
			<div class="flex justify-between items-center border-b py-4  mb-8 md:mb-12 xl:mb-1">
		
					<!-- Website Logo -->
					<a href="/" class="inline-flex items-center text-black-800 text-2xl md:text-3xl font-bold gap-2.5" aria-label="logo">
					<!-- <img src="@/assets/logo.png" class="w-6 h-auto text-indigo-500"/> -->
						Anthony Lahire
					</a>
					
					<!-- Primary Navbar items -->
					<div class="hidden lg:flex gap-12">
						<template v-for="item in items">
							<a :key="item.link" :href="item.link" 
								class="text-gray-600 hover:text-indigo-500 active:text-indigo-700 text-lg font-semibold transition duration-100"
								:class="{'font-bold text-indigo-800 border-indigo-800 border-b-4': item.code ==activeMenu}">{{item.label}}</a>
						</template> 
						<!-- <a href="#cvSection" class="text-indigo-500 text-lg font-semibold">À propos</a>
						<a href="#process" class="text-gray-600 hover:text-indigo-500 active:text-indigo-700 text-lg font-semibold transition duration-100">Mon Parcours</a>
						<a href="#skill-section" class="text-gray-600 hover:text-indigo-500 active:text-indigo-700 text-lg font-semibold transition duration-100">Mes compétences</a>
						<a href="#realisationSection" class="text-gray-600 hover:text-indigo-500 active:text-indigo-700 text-lg font-semibold transition duration-100">Mes réalisations</a> -->
					</div>

				
				<!-- Secondary Navbar items -->
				<div class="hidden md:flex items-center space-x-3 ">
					<!-- <a href="#contactSection" class="hidden lg:inline-block bg-gray-200 hover:bg-gray-300 focus-visible:ring ring-indigo-300 text-gray-500 active:text-gray-700 text-sm md:text-base font-semibold text-center rounded-lg outline-none transition duration-100 px-8 py-3">Me contacter</a> -->
					<a  :href="fileUrl" download id="downloadBtn" 
					title="Télécharger le CV en pdf"
					class="hover:scale-110 transition duration-200 hidden lg:inline-block bg-indigo-500 hover:bg-indigo-600 active:bg-indigo-700 focus-visible:ring ring-indigo-300 text-white  active:text-gray-700 text-lg md:text-base font-semibold text-center rounded-full outline-none  px-8 py-3">
							<font-awesome-icon icon="file-download" />
							<!-- <span style="max-width:0px; overflow:hidden;display: inline-block;" class="transition-all duration-100"> Télécharger</span> -->
					</a>
				</div>
				<!-- Mobile menu button -->
				<!-- <div class="md:hidden flex items-center">
					<button class="outline-none mobile-menu-button" @click="toggleMenu">
					<svg class=" w-6 h-6 text-gray-500 hover:text-green-500 "
						x-show="!showMenu"
						fill="none"
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path d="M4 6h16M4 12h16M4 18h16"></path>
					</svg>
				</button>
				</div> -->
				<div class="inline-flex lg:hidden gap-2 px-2.5 py-2">
				<button type="button" @click="toggleMenu" class="inline-flex items-center lg:hidden bg-gray-200 hover:bg-gray-300 focus-visible:ring ring-indigo-300 text-gray-500 active:text-gray-700 text-sm md:text-base font-semibold rounded-lg gap-2 px-2.5 py-2">
				<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
					<path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
				</svg>
				Menu
				</button>
				<a  :href="fileUrl" download   id="downloadBtn" class="inline-flex items-center lg:hidden  bg-gray-200 hover:bg-gray-300 focus-visible:ring ring-indigo-300 text-gray-500 active:text-gray-700 text-lg md:text-base font-semibold text-center rounded-lg outline-none transition-all duration-100 px-8 py-3">
						<font-awesome-icon icon="file-download" />
						<!-- <span style="max-width:0px; overflow:hidden;display: inline-block;" class="transition-all duration-100"> Télécharger</span> -->
				</a>
				</div>
		
			</div>
		</div>
		<!-- mobile menu -->
		<div class="mobile-menu border-b mb-4" :class="[{'hidden' : hiddenMobileMenu}]">
			<ul class="">
				<template v-for="item in items">
					<li :key="item.link"  class="transition-transform duration-300" :class="{'font-bold text-indigo-800 transform translate-x-4': item.code ==activeMenu}">
						<a :href="item.link" @click="toggleMenu"
						class="rounded-lg block text-lg px-2 mx-2 py-4 hover:bg-indigo-500 transition duration-300">{{item.label}}</a>
					</li>
				</template>
			</ul>
		</div>
	</nav>
</template>

<script>

export default {
   
 name: 'al-menu',
 props: {
	activeMenu : {type: String}
 },
 data: () => ({
   open : true
   ,fileUrl : "/files/cv_lahire_anthony.pdf"
   ,hiddenMobileMenu : true
   ,items : [{label : "À propos", link: "#about", code: "cv"}
				,{label : "Mon Parcours", link: "#experiences", code:"process"}
				,{label : "Mes compétences", link: "#competences", code :"skill"}
				,{label : "Mes réalisations", link: "#projets", code:"project"}
				,{label : "Me contacter", link: "#contact", code:"contact"}
			]
  }),
  mounted: function () {

  }
  ,computed: {
      
  }
  , methods:{
	toggleMenu(){
		this.hiddenMobileMenu = !this.hiddenMobileMenu;
	}
	}
}
</script>

<style>
#downloadBtn:hover span{
	max-width: unset !important;
}
</style>