<template>
<div>
	<h1 class="text-gray-800 text-2xl sm:text-3xl font-bold text-center md:text-left sticky top-0 pt-4 pb-4 bg-white">
    Intégration VueJS dans un projet JSF
       <img src="@/assets/logo-akelio.jpg" loading="lazy" class="float-right  rounded-lg shadow-lg h-12 rounded-lg" style="max-width:250px;">
  </h1>
	<h2 class="text-indigo-500 font-bold text-center md:text-left mt-4 md:mt-6"> Présentation</h2>
  <p class="text-gray-500 sm:text-lg  border-indigo-800 border-l-2 pl-4 mb-2">
    Notre application (Acollab), repose sur la technologie JSF proposant un rendu des écrans réalisé coté serveur. Il s'agit d'une technologie qui fonctionne, mais qui se voit limiter
    lorsque nous souhaitons intégrer plus de dynamisme sur nos pages.
  </p>
   <p class="text-gray-500 sm:text-lg  border-indigo-800 border-l-2 pl-4 mb-2">
     Suite à la réussite du projet de migration d'AngularJS vers Vue.js, nous nous sommes dit que nous pourrions profiter de cette expérience acquise et intégrer Vue.Js dans ce projet.
   </p>
  <h2 class="text-indigo-500 font-bold text-center md:text-left mt-4 md:mt-6"> Présentation</h2>
    <p class="text-gray-500 sm:text-lg  border-indigo-800 border-l-2 pl-4 mb-2">
      Avant de débuter, nous avions plusieurs craintes. Notamment sur l'acceptation de JSF pour ajouter des composants personnalisés et des attributs non standards. 
      La réalisation d'un POC permettrait alors de s'assurer la faisabilité technique de notre projet.
    </p>
    <p class="text-gray-500 sm:text-lg  border-indigo-800 border-l-2 pl-4 mb-2">
      J'ai donc commencé par inclure la lib Vue.js dans le projet, et créé un premier composant très basique (affichant seulement un titre statique). En quelques instant 
      j'ai donc pu confirmer que Vue.js s'intègre parfaitement dans notre application JSF.
    </p>
   <p class="text-gray-500 sm:text-lg  border-indigo-800 border-l-2 pl-4 mb-2">
     Maintenant que l'intégration est confirmée. J'ai poussé le test un peu plus loin en souhaitant dynamiser mon premier composant très basique pour que le titre statique devienne dynamique et puisse être  transmis via JSF par exemple.
     Là encore pas de mauvaise surprise.
   </p>
    <p class="text-gray-500 sm:text-lg  border-indigo-800 border-l-2 pl-4 mb-2">
      L'étape suivante consistait maintenant à permettre à notre composant de récupérer des données de façon asynchrone, ce qui permet d'apporter le sentiment de fluidité à l'utilisateur final.
      Cette fois ci, cela n'a pas été aussi simple que les étapes précédentes car la partie backEnd du projet n'était pas prévu pour cela. En effet le rendu étant réalisé coté serveur jusqu'à présent nous n'avions pas
      moyen de récupérer les données brutes que notre composant pourrait exploiter.
    </p>
    <p class="text-gray-500 sm:text-lg  border-indigo-800 border-l-2 pl-4 mb-2">
      Nous avons donc créé un webservice permettant de récupérer ces données. La mise en place de ce webservice a contraint également à la mise en place d'un mécanisme de sécurité pour controler l'accès aux données.
      Nous ne pouvions pas utiliser la session courante de l'utilisateur car notre volonté était de proposer des webservice stateless. Lors de la connexion de l'utilisateur, nous stockons donc un cookie contenant un token d'accès. 
      L'avantage du cookie, est qu'il est envoyé automatiquement lors de l'appel à nos services du même domaine. Nous n'avons alors pas à nous soucier d'ajouter l'information d'authentification manuellement.
    </p>
</div>
</template>

<script>

export default {
   
 name: 'archiving-data',
 data: () => ({
   open : true
  }),
  mounted: function () {

  }
  ,computed: {
      
  }
  , methods:{
    
    }
}
</script>

<style>

</style>