<template>
  <div class="skills-bloc">
  <div class="bg-white py-6 sm:py-8 lg:py-12">
  <div class="max-w-screen-2xl px-4 md:px-8 mx-auto">
    <!-- text - start -->
    <!-- <div class="mb-10 md:mb-16">
      <h2 class="text-gray-800 text-2xl lg:text-3xl font-bold text-center mb-4 md:mb-6">Our competitive advantage</h2>

      <p class="max-w-screen-md text-gray-500 md:text-lg text-center mx-auto">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pharetra turpis nunc justo, amet, dolor ut fermentum. Ac augue bibendum orci pulvinar cras consequat nulla.
      </p>
    </div> -->
    <!-- text - end -->
     <h1 class="text-gray-800 text-2xl sm:text-3xl font-bold text-center md:text-left mb-4">Langage</h1>
    <div class="grid sm:grid-cols-2 xl:grid-cols-3 gap-8 md:gap-12 xl:gap-16 mb-16">
    <div class="flex gap-4 md:gap-6 transform hover:scale-110 transition duration-20">
        <div class="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center flex-shrink-0 bg-white text-white rounded-lg md:rounded-xl shadow-lg">
          <!-- <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
          </svg> -->
          <!-- <font-awesome-icon :icon="['fab', 'java']" class="fa-2x " /> -->
            <img src="@/assets/skills/java.png" loading="lazy" class="shadow-lg rounded-lg bg-white" style=" ">
        </div>
         
        <div class="w-full">
          <h3 class="text-lg md:text-xl font-semibold mb-2">Java</h3>
              <div class="h-5 from-indigo-400 to-indigo-700 bg-gradient-to-tr rounded-lg md:rounded-xl shadow-lg" style="width:90%"/>
        </div>
    </div>

    <div class="flex gap-4 md:gap-6  transform hover:scale-110 transition duration-20">
       <div class="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center flex-shrink-0 bg-white text-white rounded-lg md:rounded-xl shadow-lg">
            <img src="@/assets/skills/js.png" loading="lazy" class="shadow-lg rounded-lg bg-white" style=" ">
        </div>
        <div class="w-full">
          <h3 class="text-lg md:text-xl font-semibold mb-2">Javascript</h3>
              <div class="w-1/6 h-5 from-indigo-400 to-indigo-700 bg-gradient-to-tr rounded-lg md:rounded-xl shadow-lg" style="width:90%"/>
        </div>
    </div>

    <div class="flex gap-4 md:gap-6  transform hover:scale-110 transition duration-20">
      <div class="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center flex-shrink-0 bg-white text-white rounded-lg md:rounded-xl shadow-lg">
            <img src="@/assets/skills/mysql.png" loading="lazy" class=" rounded-lg bg-white" style=" ">
        </div>
        <div class="w-full">
          <h3 class="text-lg md:text-xl font-semibold mb-2">Mysql</h3>
              <div class=" h-5 from-indigo-400 to-indigo-700 bg-gradient-to-tr rounded-lg md:rounded-xl shadow-lg" style="width:90%"/>
        </div>
    </div>

    <div class="flex gap-4 md:gap-6  transform hover:scale-110 transition duration-20">
        <div class="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center flex-shrink-0 bg-white text-white rounded-lg md:rounded-xl shadow-lg">
            <img src="@/assets/skills/html5-with-wordmark-color.svg" loading="lazy" class=" rounded-lg bg-white" style=" ">
        </div>
        <div class="w-full">
          <h3 class="text-lg md:text-xl font-semibold mb-2">HTML / CSS</h3>
              <div class=" h-5 from-indigo-400 to-indigo-700 bg-gradient-to-tr rounded-lg md:rounded-xl shadow-lg" style="width:80%"/>
        </div>
    </div>

    <div class="flex gap-4 md:gap-6  transform hover:scale-110 transition duration-20">
           <div class="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center flex-shrink-0 bg-white text-white rounded-lg md:rounded-xl shadow-lg">
            <img src="@/assets/skills/vbnet.png" loading="lazy" class=" rounded-lg bg-white" style=" ">
        </div>
        <div class="w-full">
          <h3 class="text-lg md:text-xl font-semibold mb-2">VB.net</h3>
              <div class=" h-5 from-indigo-400 to-indigo-700 bg-gradient-to-tr rounded-lg md:rounded-xl shadow-lg" style="width:50%"/>
        </div>
    </div>
    <div class="flex gap-4 md:gap-6  transform hover:scale-110 transition duration-20">
           <div class="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center flex-shrink-0 bg-white text-white rounded-lg md:rounded-xl shadow-lg">
            <img src="@/assets/skills/typescript.png" loading="lazy" class=" rounded-lg bg-white" style=" ">
            </div>
        <div class="w-full">
          <h3 class="text-lg md:text-xl font-semibold mb-2">TypeScript</h3>
              <div class=" h-5 from-indigo-400 to-indigo-700 bg-gradient-to-tr rounded-lg md:rounded-xl shadow-lg" style="width:50%"/>
        </div>
    </div>

     <div class="flex gap-4 md:gap-6  transform hover:scale-110 transition duration-20">
           <div class="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center flex-shrink-0 bg-white text-white rounded-lg md:rounded-xl shadow-lg">
            <img src="@/assets/skills/xml.png" loading="lazy" class=" rounded-lg bg-white" style=" ">
            </div>
        <div class="w-full">
          <h3 class="text-lg md:text-xl font-semibold mb-2">XML / XSL</h3>
              <div class=" h-5 from-indigo-400 to-indigo-700 bg-gradient-to-tr rounded-lg md:rounded-xl shadow-lg" style="width:60%"/>
        </div>
    </div>

    </div>

  <h1 class="text-gray-800 text-2xl sm:text-3xl font-bold text-center md:text-left mb-4">Framework</h1>
    <div class="grid sm:grid-cols-2 xl:grid-cols-3 gap-8 md:gap-12 xl:gap-16 mb-16">
       <div class="flex gap-4 md:gap-6  transform hover:scale-110 transition duration-20">
           <div class="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center flex-shrink-0 bg-white text-white rounded-lg md:rounded-xl shadow-lg">
            <img src="@/assets/skills/hibernate.png" loading="lazy" class=" rounded-lg bg-white" style=" ">
            </div>
        <div class="w-full">
          <h3 class="text-lg md:text-xl font-semibold mb-2">Hibernate</h3>
              <div class=" h-5 from-indigo-400 to-indigo-700 bg-gradient-to-tr rounded-lg md:rounded-xl shadow-lg" style="width:65%"/>
        </div>
      </div>

       <div class="flex gap-4 md:gap-6  transform hover:scale-110 transition duration-20">
           <div class="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center flex-shrink-0 bg-white text-white rounded-lg md:rounded-xl shadow-lg">
            <img src="@/assets/skills/spring.png" loading="lazy" class=" rounded-lg bg-white" style=" ">
            </div>
        <div class="w-full">
          <h3 class="text-lg md:text-xl font-semibold mb-2">Spring</h3>
              <div class=" h-5 from-indigo-400 to-indigo-700 bg-gradient-to-tr rounded-lg md:rounded-xl shadow-lg" style="width:72%"/>
        </div>
      </div>

       <div class="flex gap-4 md:gap-6  transform hover:scale-110 transition duration-20">
           <div class="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center flex-shrink-0 bg-white text-white rounded-lg md:rounded-xl shadow-lg">
            <img src="@/assets/skills/vuejs.png" loading="lazy" class=" rounded-lg bg-white" style=" ">
            </div>
        <div class="w-full">
          <h3 class="text-lg md:text-xl font-semibold mb-2">VueJs</h3>
              <div class=" h-5 from-indigo-400 to-indigo-700 bg-gradient-to-tr rounded-lg md:rounded-xl shadow-lg" style="width:80%"/>
        </div>
      </div>
             <div class="flex gap-4 md:gap-6  transform hover:scale-110 transition duration-20">
           <div class="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center flex-shrink-0 bg-white text-white rounded-lg md:rounded-xl shadow-lg">
            <img src="@/assets/skills/angular.png" loading="lazy" class=" rounded-lg bg-white" style=" ">
            </div>
        <div class="w-full">
          <h3 class="text-lg md:text-xl font-semibold mb-2">Angular</h3>
              <div class=" h-5 from-indigo-400 to-indigo-700 bg-gradient-to-tr rounded-lg md:rounded-xl shadow-lg" style="width:45%"/>
        </div>
      </div>
        <div class="flex gap-4 md:gap-6  transform hover:scale-110 transition duration-20">
           <div class="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center flex-shrink-0 bg-white text-white rounded-lg md:rounded-xl shadow-lg">
            <img src="@/assets/skills/angular.png" loading="lazy" class=" rounded-lg bg-white" style=" ">
            </div>
        <div class="w-full">
          <h3 class="text-lg md:text-xl font-semibold mb-2">AngularJS</h3>
              <div class=" h-5 from-indigo-400 to-indigo-700 bg-gradient-to-tr rounded-lg md:rounded-xl shadow-lg" style="width:65%"/>
        </div>
      </div>

    </div>

    <h1 class="text-gray-800 text-2xl sm:text-3xl font-bold text-center md:text-left mb-4">Autres</h1>
    <div class="grid sm:grid-cols-2 xl:grid-cols-3 gap-8 md:gap-12 xl:gap-16">
     
     
      <div class="flex gap-4 md:gap-6  transform hover:scale-110 transition duration-20">
           <div class="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center flex-shrink-0 bg-white text-white rounded-lg md:rounded-xl shadow-lg">
            <img src="@/assets/skills/ansible.png" loading="lazy" class=" rounded-lg bg-white" style=" ">
            </div>
        <div class="w-full">
          <h3 class="text-lg md:text-xl font-semibold mb-2">Ansible</h3>
              <div class=" h-5 from-indigo-400 to-indigo-700 bg-gradient-to-tr rounded-lg md:rounded-xl shadow-lg" style="width:70%"/>
        </div>
      </div>

      <div class="flex gap-4 md:gap-6  transform hover:scale-110 transition duration-20">
           <div class="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center flex-shrink-0 bg-white text-white rounded-lg md:rounded-xl shadow-lg">
            <img src="@/assets/skills/prometheus.png" loading="lazy" class=" rounded-lg bg-white" style=" ">
            </div>
        <div class="w-full">
          <h3 class="text-lg md:text-xl font-semibold mb-2">Prometheus</h3>
              <div class=" h-5 from-indigo-400 to-indigo-700 bg-gradient-to-tr rounded-lg md:rounded-xl shadow-lg" style="width:65%"/>
        </div>
      </div>

     <div class="flex gap-4 md:gap-6  transform hover:scale-110 transition duration-20">
           <div class="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center flex-shrink-0 bg-white text-white rounded-lg md:rounded-xl shadow-lg">
            <img src="@/assets/skills/grafana.png" loading="lazy" class=" rounded-lg bg-white" style=" ">
            </div>
        <div class="w-full">
          <h3 class="text-lg md:text-xl font-semibold mb-2">Grafana</h3>
              <div class=" h-5 from-indigo-400 to-indigo-700 bg-gradient-to-tr rounded-lg md:rounded-xl shadow-lg" style="width:65%"/>
        </div>
      </div>

     <div class="flex gap-4 md:gap-6  transform hover:scale-110 transition duration-20">
           <div class="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center flex-shrink-0 bg-white text-white rounded-lg md:rounded-xl shadow-lg">
            <img src="@/assets/skills/git.png" loading="lazy" class=" rounded-lg bg-white" style=" ">
            </div>
        <div class="w-full">
          <h3 class="text-lg md:text-xl font-semibold mb-2">GIT</h3>
              <div class=" h-5 from-indigo-400 to-indigo-700 bg-gradient-to-tr rounded-lg md:rounded-xl shadow-lg" style="width:60%"/>
        </div>
      </div>

     <div class="flex gap-4 md:gap-6  transform hover:scale-110 transition duration-20">
           <div class="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center flex-shrink-0 bg-white text-white rounded-lg md:rounded-xl shadow-lg">
            <img src="@/assets/skills/svn.png" loading="lazy" class=" rounded-lg bg-white" style=" ">
            </div>
        <div class="w-full">
          <h3 class="text-lg md:text-xl font-semibold mb-2">SVN</h3>
              <div class=" h-5 from-indigo-400 to-indigo-700 bg-gradient-to-tr rounded-lg md:rounded-xl shadow-lg" style="width:55%"/>
        </div>
      </div>
  
     <div class="flex gap-4 md:gap-6  transform hover:scale-110 transition duration-20">
           <div class="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center flex-shrink-0 bg-white text-white rounded-lg md:rounded-xl shadow-lg">
            <img src="@/assets/skills/rest.png" loading="lazy" class=" rounded-lg bg-white" style=" ">
            </div>
        <div class="w-full">
          <h3 class="text-lg md:text-xl font-semibold mb-2">Rest</h3>
              <div class=" h-5 from-indigo-400 to-indigo-700 bg-gradient-to-tr rounded-lg md:rounded-xl shadow-lg" style="width:75%"/>
        </div>
      </div>
  
     <div class="flex gap-4 md:gap-6  transform hover:scale-110 transition duration-20">
           <div class="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center flex-shrink-0 bg-white text-white rounded-lg md:rounded-xl shadow-lg">
            <img src="@/assets/skills/tdd.png" loading="lazy" class=" rounded-lg bg-white" style=" ">
            </div>
        <div class="w-full">
          <h3 class="text-lg md:text-xl font-semibold mb-2">TDD</h3>
              <div class=" h-5 from-indigo-400 to-indigo-700 bg-gradient-to-tr rounded-lg md:rounded-xl shadow-lg" style="width:50%"/>
        </div>
      </div>

    </div>


  </div>
</div>
  </div>
</template>

<script>
export default {
 name: 'skillsBloc',
  computed: {
  
 },
 data: () => ({
   skills : [
        {label:"html/css", note:80, category : "langage"},
        {label:"Java", note :90, category : "langage"},
        {label:"SQL", note :90, category : "langage"},
        {label:"Mysql - admin", note :90, category : "other"},
        {label:"Angular", note :90, category : "framework"},
        {label:"Javascript", note :90, category : "langage"},
        {label:"Angular JS", note :90, category : "framework"},
        {label:"Vue.js", note :90, category : "framework"},
        {label:"Linux", note :90, category : "other"},
        {label:"vb .net", note :90, category : "langage"},
        {label:"Hibernate", note :90, category : "framework"},
        {label:"Eclipse", note :90, category : "other"},
        {label:"SVN", note :90, category : "soft"},
        {label:"Git", note :90, category : "soft"},
        {label:"Rest", note :90, category : "other"},
        {label:"TDD", note :90, category : "other"},
        {label:"Ansible", note :90, category : "soft"},
        {label:"Gestion de projet", note :90, category : "soft"}
    ],
    progressBarClass: "h-5 from-indigo-400 to-indigo-700 bg-gradient-to-tr rounded-lg md:rounded-xl shadow-lg"
 })
}
</script>

<style>

</style>