import axios from 'axios'

export default() => {
	let header = {};
	header['Accept'] = 'application/json';
	header['Content-Type'] = 'application/json';
	
    return axios.create({
        baseURL: `https://anthonyl.alwaysdata.net/`,
        withCredentials: false,
        headers: header
    })
}