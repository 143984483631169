<template>
<div class="_bg-gray-100 font-nunito">
  <menu-block :activeMenu="activeMenu"></menu-block>
<section>
  <hero-block></hero-block>
</section>


<div v-scroll-spy>
<section ref="about" id="about" class=" mx-auto sm:w-full md:w-full lg:w-11/12 xl:w-11/12"  style="min-height: 50vh;">
      <h1 class=" text-6xl font-bold text-indigo-600  top-16" >
        A propos
      </h1>
      <cv-block></cv-block>
</section>
<section ref="experiences" id="experiences" class="part-section mx-auto sm:w-full md:w-full lg:w-11/12 xl:w-11/12"  style="min-height: 50vh;">
      <h1 class="text-6xl font-bold text-indigo-600  top-16" >
        Mon Parcours
      </h1>

    <process-block></process-block>
</section>
<section ref="competences" id="competences" class="part-section mx-auto sm:w-full md:w-full lg:w-11/12 xl:w-11/12"  style="min-height: 50vh;">
      <h1 class="text-6xl font-bold text-indigo-600  top-16" >
        Mes compétences
      </h1>

    <skills-block></skills-block>
</section>
<section ref="projectSection" id="projets" class="part-section main  mx-auto sm:w-full md:w-full lg:w-11/12 xl:w-11/12"  style="min-height: 50vh;">
      <h1 class="text-6xl font-bold text-indigo-600  " >
        Mes réalisations
      </h1>
      <projects-block></projects-block>
</section>
<section ref="contact" id="contact"  class=" mx-auto sm:w-full md:w-full lg:w-11/12 xl:w-11/12" style=" part-sectionmin-height: 50vh;">
        <h1 class="text-6xl font-bold text-indigo-600  " >
        Me contacter
      </h1>
       <contact-block></contact-block>

</section>
</div>
<section class="footer _bg-gray-100">
 <!-- <div class="h-16 bg-gradient-to-b to-green-400 from-blue-500" style="  height: 20vh;       ;">
      <div class="relative bg-gray-100 top-0 w-full" style="  border-radius: 0px 0px 45% 45%  / 0px 0px 50% 50%;  height: 10vh;  top: -0.1vh;     ;"/>
     
  </div> -->
  <footer-block></footer-block>
</section>


</div>
</template>

<script>

import  menuBlock from "./components/view/menu.vue";
import  cvBlock from "./components/view/cv.vue";
import  contactBlock from "./components/view/contact.vue";
import  footerBlock from "./components/view/footer.vue";
// import  heroBlock from "./components/header.vue";
import  heroBlock from "./components/view/header_test1.vue";
import  skillsBlock from "./components/view/skills.vue";
import  projectsBlock from "./components/view/projects_test1.vue";
import  processBlock from "./components/view/process.vue";
export default {
  name: 'App',

  components: {
    menuBlock,cvBlock,heroBlock,contactBlock,footerBlock,projectsBlock,skillsBlock,processBlock
  },

  data: () => ({
    skills : [
        {label:"html/css", note:80, category : "langage"},
        {label:"Java", note :90, category : "langage"},
        {label:"SQL", note :90, category : "langage"},
        {label:"Mysql - admin", note :90, category : "other"},
        {label:"Angular", note :90, category : "framework"},
        {label:"Javascript", note :90, category : "langage"},
        {label:"Angular JS", note :90, category : "framework"},
        {label:"Vue.js", note :90, category : "framework"},
        {label:"Linux", note :90, category : "other"},
        {label:"vb .net", note :90, category : "langage"},
        {label:"Hibernate", note :90, category : "framework"},
        {label:"Eclipse", note :90, category : "other"},
        {label:"SVN", note :90, category : "soft"},
        {label:"Git", note :90, category : "soft"},
        {label:"Rest", note :90, category : "other"},
        {label:"TDD", note :90, category : "other"},
        {label:"Ansible", note :90, category : "soft"},
        {label:"Gestion de projet", note :90, category : "soft"}
    ],
    skillsCategories : {
      langage : {label:"Langages", bgColor : "bg-gradient-to-br from-red-500 to-red-300 hover:from-green-400 hover:to-blue-500 cursor"},
      framework : {label:"Frameworks", bgColor : "bg-gradient-to-br from-green-500 to-green-300 hover:from-green-400 hover:to-blue-500 cursor"},
      soft : {label:"Logiciels", bgColor : "bg-gradient-to-br from-indigo-600 to-indigo-400 hover:from-green-400 hover:to-blue-500 cursor"},
      other : {label:"Divers", bgColor : "bg-gradient-to-br from-yellow-500 to-yellow-300 hover:from-green-400 hover:to-blue-500 cursor"},
    }
    ,activeSkill : null
    ,activeMenu : null
  }),
  mounted: function () {
    let path = window.location.pathname;
    let hash = window.location.hash
    if("" == hash && null != path && "/" !== path ){
      // let scrollTo = document.getElementById(path.replace("/",""));
      // if(null != scrollTo){
      //   this.$root.animate( { scrollTop: scrollTo.offset().top },
      //     500
      //   );
      // }
      this.$refs[path.replace("/","")]?.scrollIntoView();
    }

      this.initScrollSpy();


  }
  ,computed: {
      sortedSkill(){
        let tmp = {};
        this.skills.forEach(skill=>{
          if(null == tmp[skill.category]){
            tmp[skill.category] = {id:skill.category, skills:[]};
          }
           tmp[skill.category].skills.push(skill);
        });
        return tmp;
      }
  }
  , methods:{
    
      createJauge(elem) {
        if (elem) {
          // on commence par un clear
          while (elem.firstChild) {
            elem.removeChild(elem.firstChild);
          }
          // création des éléments
          var oMask  = document.createElement('DIV');
          var oBarre = document.createElement('DIV');
          var oSup50 = document.createElement('DIV');
          // affectation des classes
          oMask.className  = 'progress-masque';
          oBarre.className = 'progress-barre';
          oSup50.className = 'progress-sup50';
          // construction de l'arbre
          oMask.appendChild(oBarre);
          oMask.appendChild(oSup50);
          elem.appendChild(oMask);
        }
        return elem;
      },
      initJauge(elem) {
        var oBarre;
        var angle;
        var valeur;
        //
        this.createJauge( elem);
        oBarre = elem.querySelector('.progress-barre');
        valeur = elem.getAttribute('data-value');
        valeur = valeur ? valeur * 1 : 0;
        elem.setAttribute('data-value', valeur.toFixed(1));
        angle = 360 * valeur / 100;
        if (oBarre) {
          oBarre.style.transform = 'rotate(' + angle + 'deg)';
        }
      },




    initScrollSpy(){
     window.addEventListener('scroll', this.scrollSpyOnScrollEvent);
    }

    ,scrollSpyOnScrollEvent(){
      //  const yPosition = window.scrollY;
       var cvPos  = this.$refs.about.getBoundingClientRect();
       var experiences  = this.$refs.experiences.getBoundingClientRect();
       var skillPos  = this.$refs.competences.getBoundingClientRect();
       var realisationPos  = this.$refs.projectSection.getBoundingClientRect();
       var contactPos  = this.$refs.contact.getBoundingClientRect();
       let thresold = 250;
        let activeMenu = null;
       
      if(contactPos.top<thresold) activeMenu="contact";
      else if(realisationPos.top<thresold) activeMenu="project";
      else if(skillPos.top<thresold) activeMenu="skill";
      else if(experiences.top<thresold) activeMenu="process";
      else if(cvPos.top<thresold) activeMenu="cv";
      if(this.activeMenu !== activeMenu)  this.activeMenu = activeMenu;
      //this.enableActiveMenu(activeMenu);
      // if (yPosition >= $section.getBoundingClientRect().top) {
      //     return $links
      //       .removeClass('active')
      //       .filter(`[href="#${$section.attr('id')}"]`)
      //       .addClass('active');
      //   }
    }
    ,enableActiveMenu(p_section){
      this.$refs.skillMenu.classList.toggle("active-menu" , p_section == 'skillMenu');
      this.$refs.cvMenu.classList.toggle("active-menu" , p_section == 'cvMenu');
      this.$refs.realisationMenu.classList.toggle("active-menu" , p_section == 'realisationMenu');
      this.$refs.contactMenu.classList.toggle("active-menu" , p_section == 'contactMenu');
    }
    ,displaySkillDetail(p_oSkill ){
        this.$set(this, 'activeSkill' , p_oSkill)
    }
    , onClickHandler() {
        // const href = $.attr(e.target, 'href');

        // e.preventDefault();
        // $root.animate(
        //   { scrollTop: $(href).offset().top },
        //   500,
        //   () => (window.location.hash = href),
        // );

        // return false;
      }
    ,getSkillBgColor(p_skill){
      if(!p_skill?.category || !this.skillsCategories[p_skill.category]) return"";
      return this.skillsCategories[p_skill.category].bgColor +"  hover:bg-gray-200 ";
    }
  }
};
</script>
<style scoped>
.gradient-text-color{
background-image: -webkit-linear-gradient(-70deg, rgb(59, 130, 246),#34d399); 
-webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    

    font-family: Alliance No\.1,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
}
.sticky.stuck {
  background-color: white;
  /* box-shadow: 0px 2px 8px 7px #0a0a0a2e; */
  box-shadow:0 2.76726px 2.21381px rgb(0 0 0 / 7%), 0 6.6501px 5.32008px rgb(0 0 0 / 4%), 0 12.5216px 10.0172px rgb(0 0 0 / 3%), 0 22.3363px 17.869px rgb(0 0 0 / 3%), 0 41.7776px 33.4221px rgb(0 0 0 / 2%), 0 100px 80px rgb(0 0 0 / 2%), 0 1px 0 0 rgb(4 13 33 / 10%)
}
.sticky.stuck div{
  background-image: -webkit-linear-gradient(-70deg, rgb(162, 250, 207), rgb(100, 172, 255)) !important; 
-webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
}


.active-menu span{
  border-bottom: 3px solid  rgba(79, 70, 229, var(--tw-text-opacity));
   /*-webkit-transition-duration: 0.1s;
    transition-duration: 0.1s; */
    /* -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out; */
}
/* 
.active-menu {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  overflow: hidden;
}

#navigator nav div:before {
    content: "";
    position: absolute;
    z-index: -1;
    right: 100%;
    left: 0;
    bottom: 0;
    background: #2980b9;
    height: 4px;
    -webkit-transition-property: right;
    transition-property: right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
#navigator nav div.active-menu:before {
    right: 0;
  } */
section::before { 
  display: block; 
  content: " "; 
  margin-top: -150px; 
  height: 150px; 
}
</style>