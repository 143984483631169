<template>
<div>
	<h1 class="text-gray-800 text-2xl sm:text-3xl font-bold text-center md:text-left sticky top-0 pt-4 pb-4 bg-white">Redmine time recording</h1>
	<h2 class="text-indigo-500 font-bold text-center md:text-left mt-4 md:mt-6"> Présentation</h2>
  <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
    Redmine time recording est un logiciel de saisie de temps permettant de recueillir le détail des activités de la journée et de les envoyer sur redmine en fin de journée. Ce projet est né d'une réflexion avec un collègue de travail, afin de nous faire gagner du temps au quotidien dans la tâche de saisie de temps.
  </p>
	<!-- <h2 class="text-indigo-500 font-bold text-center md:text-left mt-4 md:mt-6"> Présentation</h2>
 <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
   Rédaction en cours ...
 </p> -->
</div>
</template>

<script>

export default {
   
 name: 'archiving-data',
 data: () => ({
   open : true
  }),
  mounted: function () {

  }
  ,computed: {
      
  }
  , methods:{
    
    }
}
</script>

<style>

</style>