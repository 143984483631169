<template>
<div>
	<h1 class="text-gray-800 text-2xl sm:text-3xl font-bold text-center md:text-left sticky top-0 pt-4 pb-4 bg-white">Mise en place de monitoring
    <img src="@/assets/Logo_O2_Care_Services.png" loading="lazy" class="float-right  rounded-lg shadow-lg h-12 rounded-lg" style="max-width:250px;">
  </h1>
	<h2 class="text-indigo-500 font-bold text-center md:text-left mt-4 md:mt-6"> Présentation</h2>
  <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
    Hébergeant, et gérant plusieurs serveurs pour nos clients ainsi que pour les besoins interne à l'entreprise, il est essentiel de connaitre à tout moment l'état de ces serveurs
    Et d'être alerté rapidement en cas de défaillance. Pour cela nous avions besoin d'outils permettant d'industrialiser la surveillance. Il n'est en effet pas gérable de surveiller efficacement des dizaines de serveurs manuellement.
  </p>
  <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
    Avant la réalisation de ce projet, certains contrôles étaient déjà effectués via des scripts directement sur les serveurs. Bien qu'ayant le mérite d'exister, ces scripts n'étaient pas parfaits, et manquaient de fiabilité.
  </p>
  <h2 class="text-indigo-500 font-bold text-center md:text-left mt-4 md:mt-6"> Réalisation</h2>
  <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
    La première étape a de ce projet à été le recensement des besoins de surveillance. J'ai ainsi listé quelques axes principaux devant impérativement être implémentés avec la solution 
    que nous choisirons de mettre en place : <ul>
      <li>Disponibilité des serveurs</li>
      <li>Espace disque restant</li>
      <li>Application web démarrée et accessible</li>
      <li>Envoi d'une alerte en cas d'anomalie constatée</li>
    </ul>
  </p>
  <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
    Pour répondre à ces besoins nous nous sommes tournés vers la suite openSource Grafana + Prometheus + Alertmanager qui offre la possibilité de mettre en place ce que nous
    souhaitons, et d'avantage encore.
  </p>
  <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
    Une contrainte supplémentaire que j'ai pu ajouter par la suite a été la mise en place d'un mécanisme d'inscription automatique d'un nouveau serveur dans ce système de surveillance.
    Pour cela, je me suis appuyé sur Ansible, qui nous permet d'installer et configurer l'ensemble de nos serveurs pour inscrire le serveur lors de sa configuration.
  </p>
  <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
    En cas de défaillance, nous sommes alertés en fonction de l'importance de la défaillance soit par mail, soit par un channel Slack. Par exemple, l'alerte de l'espace disque restant inférieur à 20% est moins critique que l'application web qui ne répond plus.
  </p>
  <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
    
  </p>
</div>
</template>

<script>

export default {
   
 name: 'archiving-data',
 data: () => ({
   open : true
  }),
  mounted: function () {

  }
  ,computed: {
      
  }
  , methods:{
    
    }
}
</script>

<style>

</style>