import Api from '@/services/api'

export default {
    create(p_oContact){
        var bodyFormData = new FormData();
        bodyFormData.append('company', p_oContact.company);
        bodyFormData.append('mail', p_oContact.mail);
        bodyFormData.append('message', p_oContact.message);
        bodyFormData.append('nom', p_oContact.nom);
        bodyFormData.append('prenom', p_oContact.prenom);
        bodyFormData.append('topic', p_oContact.topic);

        return Api().post("/contact.php" ,bodyFormData)
    }
   
}