<template>
  <div class="contact-bloc">
  <al-banner v-if="showBanner" @hide="hideBanner" :isError="sendWithError">{{bannerMessage}}</al-banner>


   <div class="bg-white py-6 sm:py-8 lg:py-12">
  <div class="max-w-screen-2xl px-4 md:px-8 mx-auto">
    <!-- text - start -->
    <div class="mb-10 md:mb-16">
      <!-- <h2 class="text-gray-800 text-2xl lg:text-3xl font-bold text-center mb-4 md:mb-6">N'hésitez pas à me contacter</h2>

      <p class="max-w-screen-md text-gray-500 md:text-lg text-center mx-auto">
      N'hésitez pas à me contacter
      </p> -->
    </div>
    <!-- text - end -->

    <!-- form - start -->
    <div class="max-w-screen-md grid sm:grid-cols-2 gap-4 mx-auto">
      <div>
        <label for="first-name" class="inline-block text-gray-800 text-sm sm:text-base mb-2">Prénom*</label>
        <input v-model="contact.prenom" name="first-name" class="w-full bg-gray-50 transform focus:scale-105 transition duration-200 text-lg text-indigo-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2"/>
      </div>

      <div>
        <label for="last-name" class="inline-block text-gray-800 text-sm sm:text-base mb-2">Nom*</label>
        <input v-model="contact.nom" name="last-name" class="w-full bg-gray-50 transform focus:scale-105 transition duration-200 text-lg text-indigo-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2"/>
      </div>

      <div class="sm:col-span-2">
        <label for="company" class="inline-block text-gray-800 text-sm sm:text-base mb-2">Société</label>
        <input v-model="contact.company" name="company" class="w-full bg-gray-50 transform focus:scale-105 transition duration-200 text-lg text-indigo-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2"/>
      </div>

      <div class="sm:col-span-2">
        <label for="email" class="inline-block text-gray-800 text-sm sm:text-base mb-2">Email*</label>
        <input v-model="contact.mail" name="email" 
          class="w-full bg-gray-50 transform focus:scale-105 transition duration-200  text-lg text-indigo-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2"
         />
          <span v-if="null != contact.mail && !isValidEmail" class="text-red-800 text-lg font-bold"> L'adresse email saisie n'est pas valide</span>
      </div>

      <div class="sm:col-span-2">
        <label for="subject" class="inline-block text-gray-800 text-sm sm:text-base mb-2">Objet*</label>
        <input v-model="contact.topic" name="subject" class="w-full bg-gray-50 transform focus:scale-105 transition duration-200 text-lg text-indigo-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2"/>
      </div>

      <div class="sm:col-span-2">
        <label for="message" class="inline-block text-gray-800 text-sm sm:text-base mb-2">Message*</label>
        <textarea v-model="contact.message" name="message" class="w-full h-64 transform focus:scale-105 transition duration-200 text-lg bg-gray-50 text-indigo-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2"></textarea>
      </div>

      <div class="sm:col-span-2 flex justify-between items-center">
        <button @click="sendContact" :disabled="!canSend" class="disabled:opacity-50 inline-block bg-indigo-500 hover:bg-indigo-600 active:bg-indigo-700 focus-visible:ring ring-indigo-300 text-white text-sm md:text-base font-semibold text-center rounded-lg outline-none transition duration-100 px-8 py-3">Envoyer</button>

        <span class="text-gray-500 text-sm">*Requis</span>
      </div>

      <!-- <p class="text-gray-400 text-xs">
        By signing up to our newsletter you agree to our <a href="#" class="hover:text-indigo-500 active:text-indigo-600 underline transition duration-100">Pricacy Policy</a>.
      </p> -->
    </div>
    <!-- form - end -->
  </div>
</div>
  </div>
</template>

<script>
import contactService from '@/services/contact.js'
import alBanner from '@/components/UI/banner.vue'
export default {
 name: 'contactBloc',
 components:{
   alBanner
 },
  data: () => ({
   contact : {

   },
   isSending : false,
   showBanner : false,
   bannerMessage : null,
   sendWithError : false,
  }),
  mounted: function () {

  }
  ,computed: {
      canSend : function(){
        return !this.isSending && this.isFormComplete && this.isValidEmail;
      }
      ,isValidEmail:function(){
        return this.validateEmailFormat(this.contact?.mail)
      }
      ,isFormComplete:function(){
        return null != this?.contact?.prenom 
          && null != this?.contact?.nom 
          && null != this?.contact?.mail 
          && null != this?.contact?.topic 
          && null != this?.contact?.message; 
      }
  }
  , methods:{
     sendContact(){
       let _this = this;
      this.isSending = true;
      // https://anthonyl.alwaysdata.net/contact.php

      contactService.create(this.contact).then(function(){
          _this.isSending = false;
          _this.sendWithError = false;
          _this.showBanner = true;
          _this.bannerMessage = "Votre message a bien été envoyé."
      }).catch(function(){
              _this.isSending = false;
              _this.showBanner = true;
              _this.sendWithError = true;
              _this.bannerMessage = "Oups ... Un problème est survenu lors de l'envoi de votre message"
      })
     }
     ,hideBanner(){
       this.showBanner = false;
     }
      ,validateEmailFormat:function(value){
        let rgexp = "^[a-z0-9]+([_|.|-]{1}[a-z0-9]+)*@[a-z0-9]+([_||-]{1}[a-z0-9]+)*[.]{1}[a-z]{2,6}$";
        return new RegExp(rgexp,"im").test(value);
      }
    }
}
</script>

<style>

</style>