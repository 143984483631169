<template>
      <div class="border border-gray-200 hover:border-0 hover:bg-gray-50 hover:shadow-lg shadow_ rounded-lg relative p-5 pt-8 transform hover:scale-110 transition duration-200 cursor-pointer" 
        :class="[{'ring ring-indigo-400 scale-105': matchFilter}]"
      @click="displayProjectDetail">
        <span class="w-8 h-8 inline-flex justify-center items-center from-indigo-400 to-indigo-700 bg-gradient-to-tr text-white rounded-full absolute -top-4 left-4">
          <font-awesome-icon :icon="icon" />
        </span>

        <h3 class="text-indigo-500 text-lg md:text-xl font-semibold mb-3">{{title}}</h3>
        <!-- <p class="text-gray-500">This is a section of some simple filler text, also known as placeholder text. It shares some characteristics of a real written text.</p> -->
      
		<template v-for="tag in this.tags" >
           <al-tag :key="tag">{{tag}}</al-tag>
		</template>

           <button href="#" class="mt-1 float-right inline-block text-indigo-500 focus-visible:ring ring-indigo-300 text-white text-sm md:text-base font-semibold text-center outline-none transition duration-100  px-2 p-1">
              <font-awesome-icon icon="search-plus" />
           </button>
        
      </div>
</template>

<script>
import  alTag from "@/components/UI/tag.vue";
export default {
    components: {
    alTag
  },
 name: 'project',
 props : {
   icon: Array
   ,tags : {type: Array, default : function(){return []}}
   ,title : {type : String}
   ,projectCode : {type:String}
   ,matchFilter:{type:Boolean ,default:function(){return false;}}
 }
 ,data: () => ({
   open : true
  }),
  mounted: function () {
     
  }
  ,computed: {
      
  }
  , methods:{
    displayProjectDetail() {
      this.$emit("displayDetail")
    }
    }
}
</script>

<style>

</style>