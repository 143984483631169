<template>
<div>
	<h1 class="text-gray-800 text-2xl sm:text-3xl font-bold text-center md:text-left sticky top-0 pt-4 pb-4 bg-white">Intégration de mails
    <img src="@/assets/Logo_O2_Care_Services.png" loading="lazy" class="float-right  rounded-lg shadow-lg h-12 rounded-lg" style="max-width:250px;">
  </h1>
	<h2 class="text-indigo-500 font-bold text-center md:text-left mt-4 md:mt-6"> Présentation</h2>
  <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
    Dans une optique de centralisation des informations et d'une optimisation du suivi des dossiers clients, l'objectif visé par ce projet est de permettre une intégration automatisée de mails au sein d'un logiciel de gestion. Le gain de temps obtenu par les utilisateurs est également un des enjeux de celui-ci.
  </p>
 <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
   Avant ce projet, un utilisateur souhaitant envoyer un mail, devait utiliser un client de messagerie externe à l'application de gestion. Puis dans un souci de qualité de suivi de dossier client, l'utilisateur devait ressaisir ce mail dans ladite application. Cette manipulation présente les inconvénients de nécessité du temps supplémentaire, ainsi qu'un risque d'oubli.
 </p>

  <h2 class="text-indigo-500 font-bold text-center md:text-left mt-4 md:mt-6"> Les mails sortants</h2>
   <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
     La fonctionnalité d'envoi de mails depuis l'application étant déjà existante, cette partie du projet n'a pas été la plus longue ni la plus difficile. Il a suffi de lier la fiche de contact avec la fonction d'envoi de mail, et de créer quelques contrôles par mesure de sécurité (que le destinataire possède bien une adresse email dans sa fiche en base de données par exemple).
  </p>
  <h2 class="text-indigo-500 font-bold text-center md:text-left mt-4 md:mt-6"> Les mails entrants</h2>
  <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
     Cette partie du projet a été la plus intéressante car complètement inexistante dans l'application.
 </p>
 <p class="text-gray-500 sm:text-lg">
    <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
      L'intéressement de cette partie est également issu de la communication entre le service développement et le service réseaux. Nous avons donc élaboré le modèle ainsi que le processus de traitement "normal" d'un mail. Ce processus devait permettre en cas d'erreur que le mail arrivant ne soit pas perdu, mais tout de même délivré à l'utilisateur.
 </p>
 <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
   Nous avons donc convenu que le service réseau devrait mettre en place un cron sur le serveur mail afin de déplacer l'ensemble des mails reçus sur un serveur de fichier. A l'aide d'un second cron, exécuté depuis l'application, chaque fichier contenu dans ce répertoire est alors parcouru et converti comme fiche de contact au sein de l'application. Dans certains cas, comme un expéditeur du mail inconnu, ou la présence d'une pièce jointe (que nous avons choisi d'ignorer), ce mail est alors renvoyé à son destinataire initial et consultable depuis son client de messagerie traditionnel.
 </p>
 <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
   Aujourd'hui, 80% des mails reçus sont correctement intégrés directement dans l'application.
 </p>
</div>
</template>

<script>

export default {
   
 name: 'project-mail',
 data: () => ({
   open : true
  }),
  mounted: function () {

  }
  ,computed: {
      
  }
  , methods:{
    
    }
}
</script>

<style>

</style>