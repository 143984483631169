<template>
  <div class="process-bloc">

    <div class="bg-white py-6 sm:py-8 lg:py-12">
       <div class="max-w-screen-2xl px-4 md:px-8 mx-auto">
        
       <div class="flex mb-8 md:flex-row flex-col">
           <div class="rounded-lg pr-8 hidden md:flex items-start">
              <img src="@/assets/logo-akelio.jpg" loading="lazy" class="shadow-lg h-28 rounded-lg sticky top-32" style="max-width:250px; ">
           </div>
        <div class="w-full">
                <h1 class="text-gray-800 text-2xl sm:text-3xl font-bold text-center md:text-left ">Développeur informatique / Chef de projet</h1>
            <p class="text-indigo-500 font-bold text-center md:text-left mb-4 md:mb-6">
              Mars 2019 – En cours <span class="md:hidden"> (Akelio)</span>
            </p>
               
            <p class="text-gray-500 sm:text-lg mb-6 md:mb-8">
              <span class="font-bold">Développement d'une application de gestion pour un grossiste en assurance</span>
              <ul class="list-inside">
              <li>Rôle de développeur et chef de projet</li>
              <li>Conception du modèle de données</li>
              <li>Développement de l'application (Front + Back)</li>
              <li>Configuration des serveurs de tests et productions.</li>
              </ul>


              <span class="font-bold">Développements pour un client dans le domaine médical dont :</span>
              <ul class="list-inside">
                <li>Développement d'un module statistique de données médicales</li>
              <li>Mise en place d'un agenda patient</li>
              <li>Migration d'une application AngularJS vers VueJs</li>
              <li>Factorisation des classes du projets</li>
              </ul>
             
              <span class="font-bold">Administration des serveurs :</span>
              <ul class="list-inside">
                <li>Configuration de serveurs</li>
              <li>Gestion des incidents (ex: incendie OVH)</li>
              <li>Mise en place d'outils de monitoring(prometheus/grafana)</li>
              <li>Mise en place d'un gestionnaire de mot de passe (bitwarden)</li>
              </ul>
                     
            </p>

            </div> 
        </div>

  <div class="flex mb-8 md:flex-row flex-col">
        <div class="w-full">
        <div  class="md:float-right">
            <h1 class="text-gray-800 text-2xl sm:text-3xl font-bold text-center md:text-left ">Architecte Technique</h1>
            <p class="text-indigo-500 font-bold text-center md:text-left mb-4 md:mb-6">
              Novembre 2017 – Février 2019 <span class="md:hidden"> (O2 Care Services)</span>
            </p>
            <p class="text-gray-500 sm:text-lg mb-6 md:mb-8">
<span class="font-bold">Administration base de données</span>
<ul class="list-inside">
<li> Surveillance de l'évolution de la taille des bases de données + Identification d'un seuil critique d'arrêt de fonctionnement du système (capacité d'ID dépassée).</li>
<li> Mise en place de la procédure de switch de serveur master mysql + gestion des réplications dans un contexte master/multi slave </li>
<li> Paramétrage des variables mysql pour améliorer la stabilité des performances.</li>
 </ul>
<span class="font-bold">Evolution du SI</span>
<ul class="list-inside">
<li> Poc + mise en place d’une architecture micro service en remplacement d’une architecture monolithe ( spring boot + netflix oss)</li>
<li> Identification des SPOF + plan d’action visant à les résoudre </li>
<li> Priorisation des sujets urgent selon leur criticité.</li>
<li> Préparation et animations de comités d'architecture.</li>
 </ul>
<span class="font-bold">Accompagnement</span>
<ul class="list-inside">
<li> Etude de faisabilité des projets </li>
<li> Elaboration de dossiers d'architecture technique</li>
<li> Assistance technique et fonctionnelle auprès des développeurs</li>
<li> Définition des conventions d’écriture des API Rest</li>
<li> Création d’un schéma d’urbanisme du SI</li>
 </ul>
<span class="font-bold">Développement</span>
<ul class="list-inside">
<li> Mise en place de la structure permettant de multi-threader les traitement de l'ordonnanceur</li>
<li> Optimisation d'une tâche planifiée causant lenteurs et perturbations sur l'utilisation au quotidien : Réduction du temps de traitement de plusieurs heures à quelques minutes. </li>
<li> Création d'une application de surveillance des serveurs applicatifs historique</li>
 </ul>

            </p>
        </div>
            </div> 
             <!-- <div class="w-72 overflow-hidden rounded-lg pl-8">
             <div class=" sticky top-0rounded-lg " style="align-self: flex-start">
              <img src="@/assets/Logo_O2_Care_Services.png" loading="lazy" class="shadow-lg  rounded-lg ">
              </div>
            </div> -->
             <div class="hidden md:flex mb-8  ">
              <div class="rounded-lg pl-8 flex items-start">
              <img src="@/assets/Logo_O2_Care_Services.png" loading="lazy" class="shadow-lg h-28 rounded-lg sticky top-32" style="max-width:250px;">
           </div>
             </div>
        </div>

  <div class="flex md:flex-row flex-col">
             <div class="rounded-lg pr-8  items-start hidden md:flex ">
              <img src="@/assets/Logo_O2_Care_Services.png" loading="lazy" class="shadow-lg h-28 rounded-lg sticky top-32" style="max-width:250px;">
           </div>
            <div class="w-full">
            <h1 class="text-gray-800 text-2xl sm:text-3xl font-bold text-center md:text-left ">Analyste Programmeur</h1>
            <p class="text-indigo-500 font-bold text-center md:text-left mb-4 md:mb-6">
              Juillet 2011 – Novembre 2017 <span class="md:hidden"> (O2 Care Services)</span>
            </p>
            <p class="text-gray-500 sm:text-lg mb-6 md:mb-8">
               <ul class="list-inside">
                <li>Prise en charge de développements (de l'analyse des besoins au suivi de la livraison)</li>
                <li>Affectation au pôle infrastructure de la DSI, se focalisant sur :</li>
              <li>L'évolution du Framework interne</li>
              <li>L'amélioration des performances de l'application</li>
                </ul>
            </p>

            </div> 
           
        </div>
         </div>


     

       
      <div class="max-w-screen-xl px-4 md:px-8 mx-auto">
         <!-- <div class="grid md:grid-cols-2 gap-8 lg:gap-12 mb-12">
      
          <div class="md:pt-8">
        
            <h1 class="text-gray-800 text-2xl sm:text-3xl font-bold text-center md:text-left ">Développeur informatique</h1>
            <p class="text-indigo-500 font-bold text-center md:text-left mb-4 md:mb-6">
              Mars 2019 – En cours
            </p>
            <p class="text-gray-500 sm:text-lg mb-6 md:mb-8">
              Développements pour un client dans le domaine médical dont :
              <ul>
                <li>Développement d'un module statistique de données médicales</li>
              <li>Mise en place d'un agenda patient</li>
              <li>Migration d'une application AngularJS vers VueJs</li>
              <li>Factorisation des classes du projets</li>
              </ul>
              Administration des serveurs :
              <ul>
                <li>Configuration de serveurs</li>
              <li>Gestion des incidents (ex: incendie OVH)</li>
              <li>Mise en place d'outils de monitoring(prometheus/grafana)</li>
              <li>Mise en place d'un gestionnaire de mot de passe (bitwarden)</li>
              </ul>
            </p>
          
          </div>
          <div class="">
            <div class="h-64 md:h-auto bg-gray-100 overflow-hidden rounded-lg shadow-lg sticky top-0">
              <img src="@/assets/logo-akelio.jpg" loading="lazy" alt="Photo by Martin Sanchez" class="w-full h-full object-cover object-center">
            </div>
          </div>
        </div> -->

        <!-- <div class="grid md:grid-cols-2 gap-8 lg:gap-12">
          <div>
            <div class="h-64 md:h-auto bg-gray-100 overflow-hidden rounded-lg shadow-lg sticky top-0">
              <img src="@/assets/Logo_O2_Care_Services.png" loading="lazy" alt="Photo by Martin Sanchez" class="w-full h-full object-cover object-center">
            </div>
          </div>

          <div class="md:pt-8">
        
            <h1 class="text-gray-800 text-2xl sm:text-3xl font-bold text-center md:text-left ">Architecte Technique</h1>
            <p class="text-indigo-500 font-bold text-center md:text-left mb-4 md:mb-6">
              Novembre 2017 – Février 2019
            </p>
            <p class="text-gray-500 sm:text-lg mb-6 md:mb-8">
              Participation à l'évolution de la stratégie du SI
              Analyse technique de faisabilité des projets
              Conseils aux développeurs sur les lignes directrices à appliquer
              Réalisation de développements
            </p>
            <h1 class="text-gray-800 text-2xl sm:text-3xl font-bold text-center md:text-left ">Analyste Programmeur</h1>
            <p class="text-indigo-500 font-bold text-center md:text-left mb-4 md:mb-6">
              Juillet 2011 – Novembre 2017
            </p>
            <p class="text-gray-500 sm:text-lg mb-6 md:mb-8">
              Prise en charge de développements (de l'analyse des besoins au suivi de la livraison)
              Affectation au pole infrastructure de la DSI, se focalisant sur :
              L'évolution du Framework interne
              L'amélioration des performances de l'application
            </p>
          </div>
        </div> -->

         
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "processBlock",
};
</script>

<style>
ul {
  text-indent: 30px;
}
</style>