<template>
  <div class="projects-bloc">
<div class="bg-white py-6 sm:py-8 lg:py-12">
  <div class="max-w-screen-2xl px-4 md:px-8 mx-auto">
    <!-- text - start -->
    <!-- <div class="mb-10 md:mb-16">
      
      <p class="max-w-screen-md text-gray-500 md:text-lg text-center mx-auto">
        This is a section of some simple filler text, also known as placeholder text. It shares some characteristics of a real written text but is random or otherwise generated.
      </p>
    </div> -->
    <!-- text - end -->
<div class="mb-10 md:mb-16 flex">
   <input  name="subject" v-model="projectFilter" placeholder="Mots clés" style="_width:90%" class="w-full transform 
        focus:scale-105 transition duration-200 text-xl text-indigo-800 
        outline-none transition duration-100 mx-3 py-2
        border-b focus:border-indigo-800 focus:border-b-4
        "/>
    <font-awesome-icon icon="search" class="text-xl text-indigo-800  ml-8 mt-3" />

</div>
    <div class="grid sm:grid-cols-2 xl:grid-cols-3 gap-8 sm:gap-y-10">
      <template v-for="project in filteredProjects">
           <al-project :key="project.name" :icon="project.icon" :tags="project.tags" :title="project.name" :matchFilter="project.matchFilter" @displayDetail="displayProjectDetail(project.code)"></al-project>
      </template>

    </div>
  </div>
</div>
  <transition name="modal" >
  <al-modal v-if="displayModal" @hide="hideProjectDetail" >
    <component :is="currentProject"></component>
  </al-modal>
  </transition>
  </div>
</template>

<script>
import  alModal from "@/components/UI/modal.vue";
import  alTag from "@/components/UI/tag.vue";
import  alProject from "@/components/UI/project.vue";
import  databaseProject from "@/components/projects/database.vue";
import  mailIntegrationProject from "@/components/projects/mailIntegration.vue";
import  archivingDataProject from "@/components/projects/archivingData.vue";
import  addVueInJSFProject from "@/components/projects/addVueInJSF.vue";
import  cacheDirectoryProject from "@/components/projects/cacheDirectory.vue";
import  migrateFromAngularjsProject from "@/components/projects/migrateFromAngularjs.vue";
import  monitoringProject from "@/components/projects/monitoring.vue";
import  rtrProject from "@/components/projects/rtr.vue";
import  thisSiteProject from "@/components/projects/thisSite.vue";
import  pronosticProject from "@/components/projects/pronostic.vue";
import {logEvent } from "firebase/analytics";
export default {
  components: {
    alModal,alTag, databaseProject, mailIntegrationProject,alProject,archivingDataProject ,
    addVueInJSFProject,cacheDirectoryProject,migrateFromAngularjsProject,monitoringProject,rtrProject,thisSiteProject,pronosticProject
  },
 name: 'projectsBloc',
 data(){
   return {
     displayModal:false
     ,currentProject : "database-project"
     ,projects : [
        {name :"Optimisation Base de données", code : "database-project", icon : ['fas','database'], tags : ['Mysql','Optimisation', 'Performance'], keyword:["DBA","O2"]},
        {name :"Intégration de mails", code : "mail-integration-project", icon : ['fas','envelope'], tags : ['Email','Java'], keyword:["O2"]},
        {name :"Archivage de données", code : "archiving-data-project", icon : ['fas','file-archive'], tags : ['Archivage','Maintien des performances','Mysql'], keyword:["O2"]},
        {name :"Ce site", code : "this-site-project", icon : ['fas','globe-europe'], tags : ["Curriculum","Projet perso", "Vue.js"], keyword:["Javascript"]},
        {name :"Mise en cache d'annuaire", code : "cache-directory-project", icon : ['fas','address-book'], tags : ["Performance","Mise en cache"],keyword:["performances","XSL","O2"]},
        {name :"Redmine time recording", code : "rtr-project", icon : ['fas','clock'], tags : ["Projet perso","Productivité","JavaFx"], keyword:["Java"]},
        {name :"Migration AngularJS vers Vue.Js", code : "migrate-from-angularjs-project", icon : ['fab', 'vuejs'], tags : ["Migration","Angular JS", "Vue.js"], keyword:["Javascript","Akelio"]},
        {name :"Intégration VueJS dans un projet JSF", code : "add-vue-in-JSF-project", icon : ['fab', 'vuejs'], tags : ["JSF", "Vue.js","Java"], keyword:["WebService","Authentification","Akelio"]},
        {name :"Mise en place du monitoring", code : "monitoring-project", icon : ['fas', 'heartbeat'], tags : ["Monitoring", "Grafana", "Prometheus"], keyword:["Serveur", "Administration","Akelio"]},
         {name :"Grossesse et pronostics", code : "pronostic-project", icon : ['fas', 'dice'], tags : ["Pronostics", "Projet perso", "Vue.js","Firebase"], keyword:["Perso", "vuejs"]}
     ]
     ,projectFilter : null
   }
 }
 ,methods :{
   displayProjectDetail: function(p_sProjectName){
       let analyticsEventProps = { item_id : p_sProjectName};
     analyticsEventProps[p_sProjectName] = true;
     logEvent(this.$analytics, 'open_project',analyticsEventProps);
     logEvent(this.$analytics, 'open_project_' +p_sProjectName);
      this.currentProject = p_sProjectName;
      this.displayModal=true;
   }
   ,hideProjectDetail:function(){
     this.displayModal = false;
   }
 }
 ,computed: {
   filters : function(){
     if(!this.projectFilter) return [];
     return this.projectFilter.split(" ").filter(filter=> {
       return filter.length >1
     });
   }
   ,filteredProjects : function(){
     return this.projects.map(project=>{
      //  let bMatch = false;
        project.matchFilter = false;
        for(let filter in this.filters){
          let filterStr = this.filters[filter].toLowerCase();
          // Recherche correspondance avec le titre
          if(project.name.toLowerCase().includes(filterStr)){
             project.matchFilter= true;
          
          // Recherche correspondance avec les tags
          }else if(project.tags){
            for(let tag in project.tags){
              if(project.tags[tag].toLowerCase().includes(filterStr)){
                  project.matchFilter= true;
              }
            }
          }
          // Recherche correspondance avec les mots clés
         if(!project.matchFilter && project.keyword){
            for(let keyword in project.keyword){
              if(project.keyword[keyword].toLowerCase().includes(filterStr)){
                  project.matchFilter= true;
              }
            }
          }
          
        }
       return project;
     });
   }
 }
}
</script>


<style>

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>