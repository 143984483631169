<template>
<div>
	<h1 class="text-gray-800 text-2xl sm:text-3xl font-bold text-center md:text-left sticky top-0 pt-4 pb-4 bg-white">Grossesse et pronostics</h1>
	<h2 class="text-indigo-500 font-bold text-center md:text-left mt-4 md:mt-6"> Présentation</h2>
  <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
    Le premier confinement est survenu peu de temps après de début de grosses de ma compagne. Nos amis et membre de la famille n'était pas au courant de cette grosses, et nous ne pouvions 
    donc pas leur annoncer en personne. J'ai donc décidé de mettre en place une application web qui permettrait d'annoncer à nos proches cette bonne nouvelle. Afin de rendre cela plus original, 
    j'ai fais en sorte que les annonces puissent être différentes en fonction de la personne qui se rendrait sur l'application.
    Enfin, j'ai ajouté un module permettant d'établir des pronostics sur la naissaance du bébé.
  </p>
  <h2 class="text-indigo-500 font-bold text-center md:text-left mt-4 md:mt-6"> Choix des technos</h2>
  <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
    Pour réaliser ce projet, j'ai opté pour l'usage de Firebase qui pourrait contenir ma base d'utilisateur, et l'enregistrement des pronostics et résultats. Ce choix s'est avant tout basé sur l'envie de découvrir un nouvel outil.
    Coté front, j'ai opté pour Vue.js (que j'utilisais depuis quelques mois déjà dans un contexte pro) que j'affectionne beaucoup. 
  </p>
   <h2 class="text-indigo-500 font-bold text-center md:text-left mt-4 md:mt-6">Identification des utilisateurs</h2>
    <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
      La première page de l'application, est une page de connexion offrant la possibilité de se connecter soit à l'aide de son compte google soit à l'aide d'une adresse email pour les utilisateurs ne possédant pas de compte google.
      Lors de la première connexion, un numéro de téléphone est demandé. Ce numéro aura préalablement été renseigné en base afin de déterminer le profil de l'utilisateur.
      Une fois le numéro renseigné, et le profil déterminé, l'utilisateur est redirigé vers la page d'annone.
    </p>
    
   <h2 class="text-indigo-500 font-bold text-center md:text-left mt-4 md:mt-6">L'annonce</h2>
    <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
      Cette page d'annonce est, comme évoqué précédemment, personnalisée en fonction du profil de l'utilisateur(futurs grand parents, oncle, amis...). J'ai ainsi pu réaliser un cadeau animé pour les membres de la familles, et un ticket à gratter pour les amis.
    </p>
	<h2 class="text-indigo-500 font-bold text-center md:text-left mt-4 md:mt-6"> La page de vote</h2>
 <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
   Chaque utilisateur peu réaliser 3 votes. Chaque vote porte sur différentes question, tel que le jour et l'heure de l'accouchement, la taille du bébé, la couleur des cheveux, le prénom, le sexe,etc...
   Une page identique à celle des votes, mais dédié à l'admin, permettra de saisir les bonnes réponses.
 </p>
 <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
   Une fois la page de résultats enregistrée, les votants n'ont plus la possibilité d'ajouter ou éditer un vote. Ils peuvent en revanche consulter leur différents vote pour consulter leur score, leur classement, et comparer leurs pronostics avec les bonnes réponses.
 </p>
  <h2 class="text-indigo-500 font-bold text-center md:text-left mt-4 md:mt-6"> Les résultats et statistiques</h2>
 <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
  Suite à la création des résultats connu j'ai pu déterminer le score de chaque votant en appliquant une pondération sur chaque question. Une bonne réponse sur la date apportera alors plus de point que sur le sexe.
 </p>
  <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
    J'ai également mis en place une page de statistique de l'ensemble des votes des participants. Je peux alors déterminer la taille moyenne, une heure moyenne, la répartition garçon/fille, etc...
  </p>
</div>
</template>

<script>

export default {
   
 name: 'archiving-data',
 data: () => ({
   open : true
  }),
  mounted: function () {

  }
  ,computed: {
      
  }
  , methods:{
    
    }
}
</script>

<style>

</style>