<template>
<div>
	<h1 class="text-gray-800 text-2xl sm:text-3xl font-bold text-center md:text-left sticky top-0 pt-4 pb-4 bg-white">
    Mise en cache d'annuaire
     <img src="@/assets/Logo_O2_Care_Services.png" loading="lazy" class="float-right  rounded-lg shadow-lg h-12 rounded-lg" style="max-width:250px;">
  </h1>
	<h2 class="text-indigo-500 font-bold text-center md:text-left mt-4 md:mt-6"> Présentation</h2>
  <p class="text-gray-500 sm:text-lg  border-indigo-800 border-l-2 pl-4 mb-2">
    L'application de gestion de l'entreprise dispose d'un annuaire contenant la liste des agences, ainsi que de l'ensemble des salariés non intervenants. Bien que pratique, cet annuaire souffrait de lenteur car il possédait l'inconvénient d'être régénéré à la volée lors de la recherche d'un utilisateur, ayant pour conséquence, un temps d'attente pour l'utilisateur et une charge serveur inutile. Il a donc été décidé que le contenu de l'annuaire serait généré à l'avance, enregistré, et simplement affiché lors de la demande de l'utilisateur.
  </p>
	<h2 class="text-indigo-500 font-bold text-center md:text-left mt-4 md:mt-6"> Réalisation</h2>
   <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
     La première étape du projet a été de déterminer le modèle de cette fonctionnalité. Nous avons donc validé la solution d'enregistrer en base de données le contenu de l'annuaire généré à l'avance.
  </p>
   <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
     Avec une génération à la volée, les données affichées étaient constamment à jour. Nous devions alors veillez à ce que les données enregistrées ne soient pas obsolètes. J'ai donc mis en place des surveillances sur les éléments affichés dans cet annuaire pour que la modification de ceux-ci provoquent une mise à jour des données enregistrées.
  </p>  
  <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
      Les résultats de l'annuaire se présentant sous la forme de liste dans laquelle chacun des éléments est dépliable afin d'obtenir plus d'informations, j'ai choisi , pour optimiser d'avantage le procédé, de diviser chacune des fiches affichables dans l'annuaire en deux parties, une partie d'entête et une partie de détail. Les résultats de recherche ne renverront ainsi que les entêtes et les détails seront chargés à la demande de l'utilisateur. Ainsi nous évitons d'envoyer 90% d'informations inutiles.
  </p>
</div>
</template>

<script>

export default {
   
 name: 'archiving-data',
 data: () => ({
   open : true
  }),
  mounted: function () {

  }
  ,computed: {
      
  }
  , methods:{
    
    }
}
</script>

<style>

</style>