import Vue from 'vue'
import App from './App.vue'
// import vuetify from './plugins/vuetify'
import router from './router'
import './index.css'
//import './../node_modules/tailwindcss/dist/tailwind.min.css';
//import './assets/tailwind.css'
import Scrollspy from 'vue2-scrollspy';
import VueMoment from 'vue-moment'

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

 
import { library } from '@fortawesome/fontawesome-svg-core'


import { faUserSecret,faDatabase ,faEye,faEnvelope,faFileArchive,faGlobeEurope,faAddressBook
  ,faClock,faHeartbeat,faSearchPlus,faFilePdf,faFileDownload,faMapMarkerAlt,faExclamationTriangle,faSearch, faDice} from '@fortawesome/free-solid-svg-icons'
import {faTimesCircle,faEnvelope as farEnvelope}  from  '@fortawesome/free-regular-svg-icons'
import { faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import { faJava, faJsSquare, faHtml5, faWindows,faVuejs} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


library.add(faUserSecret,faDatabase,faFontAwesome,faJava,faJsSquare,faHtml5
  ,faWindows,faEye,faEnvelope,faFileArchive,faGlobeEurope,faAddressBook,faClock,faVuejs,faHeartbeat,faExclamationTriangle
  , faFilePdf,faFileDownload,faMapMarkerAlt,farEnvelope,faSearch,faDice
  ,faSearchPlus,faTimesCircle);

Vue.component('font-awesome-icon', FontAwesomeIcon)

const firebaseConfig = {
  apiKey: "AIzaSyBRcnTa4BgEHwICs-vnExB9mntC8yZ5Zbo",
  authDomain: "curriculum-105fa.firebaseapp.com",
  projectId: "curriculum-105fa",
  storageBucket: "curriculum-105fa.appspot.com",
  messagingSenderId: "870470435902",
  appId: "1:870470435902:web:9bc0d772c01a287e207d6f",
  measurementId: "G-G0GL0EDCZP"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics =  getAnalytics(app);
// firebase.analytics().logEvent("notification_received");
// logEvent(analytics, 'open_project',{ item_id : 'database_project' });
Vue.prototype.$analytics =analytics
// use default options
Vue.use(Scrollspy);
Vue.use(VueMoment, {
})
Vue.config.productionTip = false

new Vue({
  // vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
