<template>
<div class="">
	<h1 class="text-gray-800 text-2xl sm:text-3xl font-bold text-center md:text-left sticky top-0 bg-white">
		Optimisation de base de données
		<img src="@/assets/Logo_O2_Care_Services.png" loading="lazy" class="float-right  rounded-lg shadow-lg h-12 rounded-lg" style="max-width:250px;">
	</h1>
	<p class="text-indigo-500 font-bold text-center md:text-left mt-4 md:mt-6"> Présentation</p>
	<p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">Dans un contexte de développement d’activité rapide, le volume de la base de données croit de façon exponentielle (+30% par an). Les solutions à court terme consistant à rajouter toujours plus de RAM sur le serveur ne suffisaient plus, d’autant que le volume important accentuait d’avantage l’imperfection de paramétrages en place.</p>
	<p class="text-gray-500 sm:text-lg  border-indigo-800 border-l-2 pl-4 mb-2">Notre base de données a donc atteint un volume d’environ 250Go dont le volume est principalement réparti dans une table de plus de 900 millions d’enregistrements, et occupant plus de 220 Go à elle seule.</p>

	<p class="text-indigo-500 font-bold text-center md:text-left mt-4 md:mt-6"> Réalisation</p>
	<p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">A l’aide de l’intervention d’un DBA expérimenté nous avons donc réalisé un audit du paramétrage et planifié des actions correctives requises.</p>

	<p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">Étalée sur 3 jours, cette mission d'optimisation a été très enrichissante en me permettant d'approfondir mes connaissances d'optimisation de MySQL que j'ai pu obtenir en recherche libre</p>

	<p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">Nous avons donc mis en évidence un manque de mémoire sur les serveurs. Utilisant le moteur InnoDb la préconisation est que l'ensemble de la base soit chargée en mémoire.</p>

	<p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">Le comportement des index a également été revu. Pour commencer ils ont été légèrement modifiés afin de supprimer une redondance. Ce changement a provoqué l'économie de 30Go de RAM. Nous étions également confrontés à des instabilités de plans d'exécutions des requêtes exécutées. Ainsi, une requête pouvait s'exécuter en quelques dixièmes de seconde, puis à tout instant, indépendamment de la charge courante du serveur, prendre plusieurs secondes voire minutes. Un mauvais paramétrage du calcul des statistiques d'index a été mis en évidence. Les statistiques étaient effectivement mis à jour trop fréquemment, et sur un échantillon de données trop faible et non représentatif du volume global. Pour résoudre ce point, nous avons donc désactivé la mise à jour automatique des statistiques (qui a ensuite été planifiée pour se réaliser de nuit), puis nous avons augmenté le nombre de pages de données à utiliser pour le calcul de ces stats. De 50 pages, le paramètre a été modifié à 10000 (sur un total 13 millions de pages)</p>


</div>
</template>

<script>

export default {
   
 name: 'project-database',
 data: () => ({
   open : true
  }),
  mounted: function () {

  }
  ,computed: {
      
  }
  , methods:{
    
    }
}
</script>

<style>

</style>