<template>
<div>
	<h1 class="text-gray-800 text-2xl sm:text-3xl font-bold text-center md:text-left sticky top-0 pt-4 pb-4 bg-white">
    Archivage de données
    <img src="@/assets/Logo_O2_Care_Services.png" loading="lazy" class="float-right  rounded-lg shadow-lg h-12 rounded-lg" style="max-width:250px;"></h1>
	<h2 class="text-indigo-500 font-bold text-center md:text-left mt-4 md:mt-6"> Présentation</h2>
  <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
    Dans un contexte d'accroissement d'activité, le volume des données en base subit une forte augmentation. Pour conserver de bonnes performances d'accès aux données nous avons décidé la mise en place d'archivage de données jugées 'obsolètes' (Par exemple, d'historique des interventions d'un client datant de 5 ans, ou les informations d'un ex-client depuis quelques années...).
  </p>
 <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
   Cet archivage ne devait pas rendre ces données complètement inaccessibles. Pour des raisons juridiques, certaines devaient pouvoir être ressorties même après archivage.
 </p>

  <h2 class="text-indigo-500 font-bold text-center md:text-left mt-4 md:mt-6"> Les enjeux</h2>
   <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
   Comme évoqué ci-dessus, le principal enjeu est la conservation des performances de l'application. Le second enjeu notable est le gain d'espace et de temps nécessaire pour les opérations de backup et restauration de bases. En effet, moins de données présentes sur la base de données représentent également moins de données backupées, par extension moins d'espace requis pour le stockage de ceux-ci, et un temps de restauration de bases de données qui s'en voit également diminué.
  </p>
  <h2 class="text-indigo-500 font-bold text-center md:text-left mt-4 md:mt-6">La réalisation</h2>
  <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
     Dans un premier temps je devais gérer l'urgence des performances et du ressenti utilisateur grandement dégradé par un volume de données grandissant sans cesse. J'ai donc commencé par déterminer un élément massivement présent en base de données permettant d'effectuer un "grand nettoyage" en une seule opération. Une fois les premières données identifiées, j'ai donc dupliqué le modèle de base de données sur un autre serveur, puis j'ai déplacé l'ensemble de celles-ci sur la nouvelle base.
 </p>
 
</div>
</template>

<script>

export default {
   
 name: 'archiving-data',
 data: () => ({
   open : true
  }),
  mounted: function () {

  }
  ,computed: {
      
  }
  , methods:{
    
    }
}
</script>

<style>

</style>