<template>
<div>
	<h1 class="text-gray-800 text-2xl sm:text-3xl font-bold text-center md:text-left sticky top-0 pt-4 pb-4 bg-white">Ce Site</h1>
	<h2 class="text-indigo-500 font-bold text-center md:text-left mt-4 md:mt-6"> Présentation</h2>
  <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
    Contrairement aux réalisations ci-dessus, celle-ci ne s'inscrit pas dans un cadre professionnel.
  </p>
   <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
     Souhaitant sortir du cadre de la simple page A4 constituant un CV, j'ai donc commencé l'élaboration de ce site. J'ai privilégié partir de zéro plutôt que l'usage de solutions prêtes à l'emploi que l'on peut trouver sur le net. Ce choix est encouragé par le souhait d'illustrer mon savoir-faire, mais également de tester certaines fonctionnalités en leur donnant un aspect concret.
  </p>
<h2 class="text-indigo-500 font-bold text-center md:text-left mt-4 md:mt-6"> La réalisation</h2>
   <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
     La première étape de la réalisation a été d'établir un premier squelette html. J'ai opté pour une structure simple avec seulement une première partie contenant un menu, et une seconde destinée à contenir les différents articles.
  </p>
  <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
    La seconde étape visait à mettre en place le mécanisme de chargement des différents articles de ce site. Pour permettre un accès plus rapide au site, j'ai découpé chaque article dans un fichier différent. Ainsi, le contenu récupéré lors du chargement initial est limité à la structure contenant le menu, ainsi que, soit la page demandée, soit la page d'accueil si aucune n'est spécifiée. Le chargement des articles se réalise via un appel ajax récupérant le contenu de l'article choisi. Ce contenu est alors ajouté au DOM puis affiché. En cas de demande d'affichage d'un article qui a déjà été affiché, il n'y a pas d'appel ajax effectué, mais uniquement un affichage de l'article déjà chargé au premier appel.
  </p>
   <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
     Une fois ces deux étapes réalisées j'ai souhaité permettre à l'utilisateur d'utiliser les fonctions "précédentes" et "suivantes" du navigateur pour une meilleure expérience de navigation. J'ai donc opté pour la modification du hash de l'url lors du changement d'article, avec un écouteur sur ce changement. Il s'agit en fait de l'écouteur qui provoque le changement d'article. Bien qu'offrant un confort de navigation à l'utilisateur, cela ouvre la possibilité de modifier directement l'url pour sélectionner un article, et donc de saisir un article inexistant. J'ai donc géré le cas où l'article n'existe pas avec affichage d'un message d'erreur.
  </p>
   <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
     Une fois le squelette écrit, et le mécanisme de changement de pages en place, j'ai commencé à rédiger la chartre graphique. Plusieurs versions ont été livrées avant d'arriver à celle actuellement en place. Chacune d'elles passant par une phase de validation par un panel d'utilisateurs m'effectuant divers retours, et suggestions.
  </p>
   <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
     Pour vous permettre de me donner votre ressenti sur ma réalisation, j'ai ensuite mis en place la fonctionnalité de contact. Cette fonctionnalité apporte un nouvel aspect dans le site, car il n'est plus un simple site de lecture, mais devient un lieu d'échange entre mon parcours, et vos impressions.
  </p>
   <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
     Pour compléter, j'ai ajouté quelques fonctionnalités supplémentaires comme par exemple le changement de page à l'aide d'un glissement sur un périphérique tactile, l'envoi de mails, et SMS lors de la réception d'un nouveau contact, le repli de l'ensemble des blocs d'une page, le fils d'ariane dynamique sur chaque page ou encore le titre de chaque page qui se fixe lors du scroll...
  </p>
  <h2 class="text-indigo-500 font-bold text-center md:text-left mt-4 md:mt-6"> La V2</h2>
   <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
     Dernièrement, j'ai décidé de changer l'aspect du site. J'en ai alors profité pour utiliser Vue.js afin de le structurer, et de tester un librairie CSS (tailwindcss) pour l'aspect visuel.
     J'ai changé de philosophie pour la navigation entre les différentes rubriques en regroupant toutes les pages en une seule permettant la vision complète de mon profil sur la même page.
  </p>
</div>
</template>

<script>

export default {
   
 name: 'archiving-data',
 data: () => ({
   open : true
  }),
  mounted: function () {

  }
  ,computed: {
      
  }
  , methods:{
    
    }
}
</script>

<style>

</style>