<template>
<div>
	<h1 class="text-gray-800 text-2xl sm:text-3xl font-bold text-center md:text-left sticky top-0 pt-4 pb-4 bg-white">Migration AngularJS vers Vue.Js
     <!-- <span class="float-right text-indigo-500 font-bold text-center md:text-left mb-4 md:mb-6 text-lg">(Akelio)</span> -->
        <img src="@/assets/logo-akelio.jpg" loading="lazy" class="float-right  rounded-lg shadow-lg h-12 rounded-lg" style="max-width:250px;">
  </h1>
	<h2 class="text-indigo-500 font-bold text-center md:text-left mt-4 md:mt-6"> Présentation</h2>
  <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
    Nous avons repris un projet existant sur lequel la partie frontEnd a été développé en AngulaJS. Ce framework étant obsolète et voué à ne plus être maintenu il a été décider de réaliser un changement 
    pour remplacer celui-ci par un autre framework plus récent et maintenu.
  </p>
  <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
    Le choix de ce nouveau framewok a été réalisé avant mon arrivé sur le projet. Vue.js a été retenu comme remplaçant.
  </p>
	<h2 class="text-indigo-500 font-bold text-center md:text-left mt-4 md:mt-6"> Réalisation</h2>
  <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
    Lors de mon arrivé sur le projet, un premier essai été déjà en place pour utiliser Vue.js sur certain écran. Cette première version a été réalisé en utilisant le système d'iFrame.
  </p>
   <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
     Pratique pour tester le framework et le valider, l'usage d'iFrame ne semblait pas viable pour une migration d'une plus grande ampleur. De plus, l'affichage de cette iFrame se réalisait en passant au préalable par une couche AngularJS.
     Il nous fallait alors trouver une solution 
     pour réaliser un système hybride entre angularJS et Vue.js
  </p>
  <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
      La plus grande difficulté a été de gérer un double système de routage, permettant d'afficher les écrans complètement en vue.js lorsque nous le souhaitons et AngularJs pour les écrans qui ne sont pas encore migré.
      Pour ce faire, j'ai décideé que les deux routages viverait en parallèle, et qu'un mécanisme de switch permettant d'afficher le contenu d'une technologie, ou d'une autre.
  </p>
  <p class="text-gray-500 sm:text-lg border-indigo-800 border-l-2 pl-4 mb-2">
    Aujourd'hui, nous avons migré une grande partie des écrans de l'application. Cette migration a été l'occasion d'implémenter de nouvelle fonction d'ergonomie pour les utilisateurs. Ce sont d'ailleurs maintenant les utilisateurs qui nous demande réaliser les migrations de certains écrans.
  </p>
</div>
</template>

<script>

export default {
   
 name: 'archiving-data',
 data: () => ({
   open : true
  }),
  mounted: function () {

  }
  ,computed: {
      
  }
  , methods:{
    
    }
}
</script>

<style>

</style>